import { Injectable,OnDestroy, OnInit } from '@angular/core';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { AlertController,LoadingController,ToastController} from '@ionic/angular';
import { BehaviorSubject, EMPTY, from, Observable, of, Subscription } from 'rxjs';
import { catchError,switchMap} from 'rxjs/operators';
import { StorageService } from './storage.service';
import { Dialog } from '@capacitor/dialog';
import { Device } from '@capacitor/device';
import { UserService } from './user.service';
import { SwiperOptions } from 'swiper';
import { Browser } from '@capacitor/browser';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { FileOpener } from '@capacitor-community/file-opener';
import { Filesystem, Directory, Encoding, DownloadFileResult, ReadFileResult } from '@capacitor/filesystem';
import {version,build} from '../../../package.json';
import { App } from '@capacitor/app';
import { Share } from '@capacitor/share';
const TOKEN_KEY = 'jwttoken';
import { environment } from "src/environments/environment";
import {
  FirebaseMessaging,
  GetTokenOptions,
} from "@capacitor-firebase/messaging";
import { StatusBar, Style } from '@capacitor/status-bar';
@Injectable({
  providedIn: 'root'
})

export class GlobalService  implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  public messageBoardData: BehaviorSubject<Array<any>> = new BehaviorSubject<[]>(null); 

public deviceInfo: any;
 
 
public apiURL: string = (location.host === "localhost:8100") ? 'http://localhost/app/' : 'https://app.connectmyclub.co.uk/app/';
//public apiURL: string = 'https://app.connectmyclub.co.uk/app/';

  public siteUrl: string = 'https://app.connectmyclub.co.uk/';
 
  private errorURL = 'ionic_postError' 
  private logoutURL = 'ionic_logout'

  logging_out = false
  private sendNotificationsURL = 'ionic_sendNotifications' 
  
  public appName : string = "connectMyClub";
  public merchantName : string = "Arden:Twenty";
  public primary_color: string = "rgb(255,120,0)";
  public primary_color_hex: string = "#ff7800";
  public statusBarText: string = "DARK";
  public clubName: string;

  public pushPermission: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public multiClubs = false;
  public stripeKey: string = (location.host === "localhost:8100") ? 'pk_test_cz3EiQMj1FVFgyXryQcdVDzt' : 'pk_live_nYhopNPkh1hQro4JATqF4cDI';
 // public stripeKey: string = 'pk_test_cz3EiQMj1FVFgyXryQcdVDzt';
//public stripeKey: string = 'pk_live_nYhopNPkh1hQro4JATqF4cDI';
  public nothingHere = "There's nothing here at the moment"
  public mapsApiKey="AIzaSyDTzinomP2r7CYWAL8V2STW0BXnXi_RV_4"


  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 


  token = '';

  loader = false;

  requestURL: string


  public isNative = Capacitor.isNativePlatform()
  public isApp = Capacitor.isNativePlatform()

  public appPlatform
  public appVersion
  public appBuild
  deviceOS
  appType

  public notificationData:any = []
 

  downloadProgress = 0
 
   // default error popups
   private errorPopup: boolean = true;
   private postingError = false;

   private inNonLoaders = false
   private isLoading = false;
   private readTimeout = 120000
   private connectTimeout = 60000 


  jwttoken
  club_id
  user_id
   
   // cache flag
   public cached: boolean = true;

   public clubLogoChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 

   public googleMapKEY = "AIzaSyDTzinomP2r7CYWAL8V2STW0BXnXi_RV_4";
 private nonLoaderURLs = [ this.apiURL+'ionic_postError',
                            this.apiURL+'ionic_postPushToken',
                            this.apiURL+'ionic_postUsageStats',
                            this.apiURL+'ionic_postSwitchDM',
                          //  this.apiURL+'ionic_updateMessageBoardLikes',
                           // this.apiURL+'ionic_pinMessageBoardPost',
                            this.apiURL+'ionic_clublogo',
                            this.apiURL+'ionic_getMembership',
                            this.apiURL+'ionic_postNotificationSettings',
                            this.apiURL+'ionic_sendNotifications',
                            this.apiURL+'ionic_userHelpSearch',
                            this.apiURL+'ionic_getTrainingGroupRegister',
                            this.apiURL+'ionic_getMessageBoard',
                            this.apiURL+'ionic_meetSearch',
                            this.apiURL+'ionic_raceSearch',
                            this.apiURL+'ionic_eventSearch',
                            this.apiURL+'ionic_getEventCalendarHistory',
                            this.apiURL+'ionic_memberSearch',
                            this.apiURL+'ionic_getTrainingLocations',
                            this.apiURL+'ionic_getHighlightEventorOffer',
                            this.apiURL+'ionic_getAppTabValues'
                            ];


 public preferWheel = false;

           public swiperConfig: SwiperOptions = {
                              slidesPerView: 1,
                              navigation: true,
                              pagination: false,
                              scrollbar: false,
                            };
                            
  constructor(
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private storage: StorageService,
    private userService: UserService, 
    private calendar: Calendar,
    private loadingCtrl: LoadingController
    ) {
      console.log("HOST : " +location.host);
      this.loadToken();
      console.log('Data Service Initialize');
    }

    showAlert = async (message) => {
      await Dialog.alert({
        title: 'Stop',
        message: message,
      });
    };

    async presentAlert( header, message, buttons) {
      const alert = await this.alertController.create({
        cssClass:'buttonCss',
        header: header,
        //subHeader: 'Subtitle',
        message: message,
        buttons: buttons
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    }


    async ngOnInit() {

  if( this.isAuthenticated ){
    this.isLoggedIn.next(true);
  }
    }

    async downloadGPX(url) {

      console.log(url)

      let route = url.match(/\d+/g)[0];

      let filename = 'route_'+route+'.gpx'

      const status = await Filesystem.checkPermissions()

      console.log(status)
      if (status.publicStorage === 'granted') {

  
        Filesystem.downloadFile({
          url: url,
          path: filename,
          directory: Directory.Documents,
          progress:true,
          recursive:true
        }).then((res: DownloadFileResult) => {
         console.log(res.path)

         if( res.path){
/*
          let message = "GPX downloaded to Documents/"+filename
          let length = 3000
          let position = "bottom"
          this.presentToast(message, length, position)

*/
          let mimeType = 'application/gpx+xml';
         this.openGpx(res.path, mimeType)

         }
        }).catch((error) => {
          console.error(error);
        });


      } else{
        
        Filesystem.requestPermissions()
      }

          };


private async openGpx(filePath: string, mimeType: string){
            //  Open the file
    let options = {
      filePath:filePath, 
      openWithDefault:true
    }

FileOpener.open(options)
.then(() => console.log('File is opened'))
.catch(e => console.log('Error opening file', e));

}

          

    async downloadPDF(url) {

console.log("URL "+url)

   let shareUrl = url.replace(this.apiURL,'')
     /*  */ 
  /**/ if( !Capacitor.isNativePlatform()){
      console.log("JUST OPEN APP")
      this.openPdf(url)
        return false;
      } 
 
   
  console.log(shareUrl)   
  const sub =   (await this.request('get', true, shareUrl, null)).subscribe(
    async (res) => {
    console.log(res)

  let mime_type = res.mime_type;

  let data = res.filedata;

  let file = res.file;

    let title = res.new_title;
    let path = res.path;
    let options
    try {
  const result = await Filesystem.writeFile({
      path: title,
      data: data,
      directory: Directory.Documents,
      recursive: true
  });
  console.log(result);
  
options = {filePath:result.uri, 
              contentType:mime_type,
              openWithDefault:true}

FileOpener.open(options)
  .then(() => console.log('File is opened'))
   .catch(e => console.log('Error opening file', e));

} catch (e) {
  console.error(e);
  this.openPdf(url)
  return false
}

    });


    this.subscriptions.add(sub)
   
 
    }
    
     


      openPdf(file){
        Browser.open({ url: file});
       }

           // Helper functions
  /*private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });*/

    async presentToast(message, length, position) {

      const toast = await this.toastCtrl.create({
        message: message,
        duration: length,
        position: position,
       // color: 'tertiary',
       // cssClass: 'toast',
        animated : true
      });
      toast.present();
    }


    async getAppDetails(){
      
      try{
           //console.log("GETTING APP DETAILS")

      await this.storage.getString('jwttoken').then(async (data: any) => {
        this.jwttoken  = data.value;

      });

      await this.userService.getUser().then(async (user) => {
        //this.jwttoken = user['jwttoken'];
       this.club_id = user['club_id'].toString();
        this.user_id = user['user_id'].toString(); 

        
    })

      await Device.getInfo().then(deviceInfo => {
        this.appPlatform =  deviceInfo.platform;
    
        this.deviceOS = deviceInfo.operatingSystem;
      });
      if( this.isApp ){
        await App.getInfo().then(appInfo => {
          this.appVersion = appInfo.version;
          this.appBuild = appInfo.build;
          this.appType = appInfo.name
        });

      }
     else if( !this.isApp ){
          this.appVersion = version
          this.appBuild = build
      }
     return true;
    } catch{


      let position = 'bottom';
      let duration = 3000;
      let message = 'Error: Please try again';

      if( this.user_id){
        await this.presentToast(message,duration,position)
      }
      
    }

     }

  
      /**
   * Generate url for all requests. It uses baseUrl if url doesn't start with 'http'' or 'www'.
   * @param url     Url string
   * @returns       Generated url string
   */
  protected async generateUrl(url: string): Promise<string> {

    return this.apiURL + url;
  }

  delay(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
   }
  /**
   * This makes request and and shows loader if asked
   * 
   * @param method RequestMode to be used 
   * @param url  URL for request
   * @param body body can be json object which will be stringify
   * @param options Any RequestOptionsArgs you want to pass
   * @returns It returns an Observable from the request.
   */  
   public async loginRequest(method, loader: boolean,  url: any, body?: any ): Promise<Observable<any>> {

    url = await this.generateUrl(url);

      this.requestURL = url
      this.loader = loader;

        
      let 
        reqHeaders = {   
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }

        let  options = {
                url: url,
                headers: reqHeaders,
                data: body,
                readTimeout: this.readTimeout,
                connectTimeout: this.connectTimeout
          }  

        this.presentLoading();

       return from( CapacitorHttp.request({ ...options, method: method })).pipe(

        switchMap(async _ => { 
          
      this.closeLoading()
          console.log(_)
         let status = _.status
         console.log(status)
          if( status === 200) {
                  return _.data;
            }
            else{
           catchError(this.handleKnownError('knownError', [_]))
            }
            }),
    catchError(async error => { 
      this.closeLoading()
      this.handleError('getDetails', [error])
    }),
      );

}


  /**
   * This makes request and and shows loader if asked
   * 
   * @param method RequestMode to be used 
   * @param url  URL for request
   * @param body body can be json object which will be stringify
   * @param options Any RequestOptionsArgs you want to pass
   * @returns It returns an Observable from the request.
   */  
   public async request(method, loader: boolean,  url: any, body?: any ): Promise<Observable<any>> {

      url = await this.generateUrl(url);

      this.requestURL = url
      this.loader = loader;

    let appDetails = await this.getAppDetails();

         let reqHeaders = {   
            'Authorization': 'bearer '+ this.jwttoken, 
            'User-Id': this.user_id,
            'Club-Id': this.club_id,
            'Appversion': this.appVersion,
            'Build': this.appBuild,
            'Platform': this.appPlatform,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }

        console.log(reqHeaders)

      let requestBody:object = {};
    if(body){
      const entries = Object.entries(body);

      entries.forEach(([key, value]) => {
        if( Number.isInteger(value) ){
          value = value.toString()
        }
        else if(!value ){
          value = "";
        }
        else if( Array.isArray(value) ){
          value = value.join(',')
        }
       else  {
          value = value.toString()
        }


        requestBody[key] = value
      });
      }

   /*   console.log(body)
    console.log(requestBody)*/

      let options
      if( method == 'get'){
          options = {
                url: url,
                headers: reqHeaders,
                params:  requestBody,
                readTimeout: this.readTimeout,
                connectTimeout: this.connectTimeout
          }   
        };
        if( method == 'post'){
          options = {
                url: url,
                headers: reqHeaders,
                data: requestBody,
                readTimeout: this.readTimeout,
                connectTimeout: this.connectTimeout
          }   
        };



   this.inNonLoaders = false
    if(this.nonLoaderURLs.includes(this.requestURL)){
      this.inNonLoaders = true
    }
    if(this.inNonLoaders === false) {
        this.presentLoading();
        }

    return from( CapacitorHttp.request({ ...options, method: method })).pipe(

        switchMap(async _ => { 
          
      this.closeLoading()
         // console.log(_)
         let status = _.status
        // console.log(status)


        /*  
         let adCount:any = 0;
         let adFrequency:any = 0;
         let adShow:any = 'false';

         await this.storage.getString('adShow').then(async (data: any) => {
          adShow = data.value
         });
         await this.storage.getString('adCount').then(async (data: any) => {
          console.log(data.value)
          if( data.value){
             adCount = parseInt(data.value)
          }
         });


        await this.storage.getString('adFrequency').then(async (data: any) => {
          adFrequency = parseInt(data.value)
         });

          console.log( "AD SHOW " +adShow)
          console.log( "AD FREQ " +adFrequency)

         if( adCount >= adFrequency && adShow == 'true'){
              this.showAD();
              console.log( "SHOWING AD")
              adCount = 0;
         }else{
              adCount = adCount + 1;
         }
          console.log( "AD COUNT " +adCount)
          adCount = adCount.toString();
          await this.storage.setString('adCount',adCount)
*/

          if( status === 200) {
                  return _.data;
            }/*else if( status === 500) {
             
              let position = 'bottom';
              let duration = 3000;
              let message = 'Please reload page';

              await this.presentToast(message,duration,position)
        }*/
            else{
           catchError(this.handleKnownError('knownError', [_]))
            }
            }),
    catchError(async error => { 
      this.closeLoading()
      this.handleError('getDetails', [error])
    }),
      );

}

private handleKnownError<T>(operation = 'operation', result?: T) {

 console.log(operation)
 console.log(result)

        let error  = result[0].error
        let status =  result[0].status
        let message = result[0].data.message

      if( message.includes('failed to connect to app.connectmyclub.co.uk')){
        message = 'Failed to connect to server';
      }
/*
      if( message.includes('An SSL error has occurred ')){
        message = 'A secure connection with the server could not me established';
      }*/

        let position =   'bottom';

        this.presentToast(message, 3000, position);


    if( status !== 418){
        this.sendError(message,status)
    }
     return message; 

     
    }

    private handleError<T>(operation = 'operation', result?: T) {

      console.log(operation)
      console.log(result)
      let code = result[0].code
      let message = result[0].message
      if( !message ){
        message = result[0].data.message
      }
      console.log(message)


      if( this.requestURL.includes("ionic_postPushToken") || this.requestURL.includes("ionic_postUsageStats")){
        return;
      }

      if( message == "SocketTimeoutException"){
        message = "Timed Out";
      }

      if( message == "Failed to fetch"){
        message = "Server error"
      }

      if( !message){
        message = "An unknown error has ocurred"
      }

      let status =  result[0].status
      let position = 'bottom';

      this.presentToast(message, 3000, position);
   
      this.sendError(message,code);

       // Let the app keep running by returning an empty result.
       return (of(result as T)); 



/*
       return (error: any): Observable<T> => {

        console.log(error)
     
         // TODO: send the error to remote logging infrastructure
         let message

         if( error.name == "TimeoutError"){
               message = error.message
               let position =   'bottom';
               this.presentToast(message, 3000, position)
             }
        else{
         message = error.error.message;
         }

         console.log(message);

         if( !message){
           message = "An unknown error has ocurred"
         }
         // TODO: better job of transforming error for user consumption
        // this.log(`${operation} failed: ${JSON.stringify(error)}`);
        console.error("SENDING ERROR");
        this.sendError(error)
     
         // Let the app keep running by returning an empty result.
         return (of(error as T)); 
       }; */
     }



  async sendError(error,status){

  
    console.log("STATUS " + status)

    console.log("SENDING ERROR "  +error)

    this.postingError = true

    const info = await Device.getInfo();

    let deviceModel = info.model

if( this.requestURL.includes("ionic_postError" )){
  return false;
}

    let dataString = {'section':this.requestURL,
                      'platform':this.appPlatform,
                      'device':deviceModel,
                      'error':error,
                      'version':this.appVersion,
                      'build':this.appBuild,
                      'status':status
                    };

        console.log(dataString)
    const sub = (await this.request('post', false, this.errorURL, dataString)).subscribe( 
    async (res) => {
      console.log(res)
      this.postingError = false

      let newToken = res.jwttoken

      await this.storage.setString('jwttoken', newToken);
/*
let message = res.message;

      let position =   'bottom';
    //  this.presentToast(message, 3000, position)*/

    
    });
  this.subscriptions.add(sub)
}



async sendNotifications(section,id){

  
  let dataString = {'section':section,
                    'id':id
                  };
    
      console.log(dataString)
      const sub = (await this.request('post', false, this.sendNotificationsURL, dataString)).subscribe( 
      async (res) => {
        console.log(res)

      });

    this.subscriptions.add(sub)
}


async presentLoading() {
  this.isLoading = true;
  this.loadingCtrl.getTop().then(async hasLoading => {

   // console.log('LOADING ' + this.inNonLoaders)
    if (!hasLoading && this.inNonLoaders == false) {
      return await this.loadingCtrl.create({
        // duration: 5000,
       // message: 'Please wait',
        mode: "ios",
        showBackdrop: true,
        spinner: "dots",
        translucent: true,
       // cssClass: "spinner",
        backdropDismiss: true
      }).then(a => {
        a.present().then(() => {
        //  console.log('presented');
          if (!this.isLoading) {
            a.dismiss().then(() => console.log('abort presenting'));
          }
        });
      });
    }
});   
}

async closeLoading(){
  this.isLoading = false;
const loading =  await this.loadingCtrl.getTop().then(async hasLoading => {
    if (hasLoading) {
      this.loadingCtrl.dismiss();
    }
  });
}
    async logout() {
      this.isAuthenticated.next(false);
      this.isLoggedIn.next(false);


      let info = await Device.getInfo();
      let platform = info.platform
      let device = await Device.getId();
      let uuid = device.identifier
      
      let dataString = {  'platform': platform,
                          'uuid': uuid
                        };
      
       this.logging_out = true

      /* logout on server */ 
    const sub =  (await this.request('post', false, this.logoutURL, dataString)).subscribe( 
        async (res) => {

         
        //  console.log(res)

          this.storage.clear();
      /* reset colours */ 

         document.documentElement.style.setProperty(`--ion-color-primary`, '#ff7800'); 
         document.documentElement.style.setProperty(`--ion-color-base-rgb`, 'rgb(255,120,0)'); 
         document.documentElement.style.setProperty(`--ion-color-primary-shade`, 'rgb(255,120,0)'); 
         document.documentElement.style.setProperty(`--ion-color-primary-tint`, 'rgb(255,120,0)'); 

         document.documentElement.style.setProperty(`--ion-color-primary-contrast`, '#FFFFFF'); 
         document.documentElement.style.setProperty(`--ion-color-contrast-rgb`, 'rgb(255,255,255)'); 

         document.documentElement.style.setProperty(`--ion-color-tertiary`, '#ff7800');
         document.documentElement.style.setProperty(`--ion-color-tertiary-rgb`, 'rgb(255,120,0)'); 
         document.documentElement.style.setProperty(`--ion-color-tertiary-shade`, 'rgb(255,120,0)'); 
         document.documentElement.style.setProperty(`--ion-color-tertiary-tint`, 'rgb(255,120,0)');

      /* remove push token */

      const deleteToken = async () => {
        await FirebaseMessaging.deleteToken();
      };
    /* */ 
    setTimeout(() => {
       window.location.replace("/");
      }, 100);


    
        });

      this.subscriptions.add(sub)


      
    }

    async setStatusBar(bg,text){

      if(  Capacitor.getPlatform() === "android" ){
   
          await StatusBar.setBackgroundColor({ color: bg });

          if( text == 'LIGHT' || text == 'light' ){
            await StatusBar.setStyle({ style: Style.Dark });
          } else if( text == 'DARK' || text == 'dark' ){
            await StatusBar.setStyle({ style: Style.Light });
          } 
      //}, 1000)

    }
    }

    async registerToken(pushToken){

      let appUsedClub = 'appused_'+this.club_id
      let reset = true;

      await this.storage.getString(appUsedClub).then(async (data: any) => {
          if(data.value == 'true'){
                  reset = false;
                }

             

//console.log("TOKEN DATA " + reset)

      let info = await Device.getInfo();
      let platform = info.platform
      let device = await Device.getId();
      let uuid = device.identifier
    
      let dataString = {  'platform': platform,
                          'uuid': uuid,
                          'token': pushToken,
                          'reset': reset
                        };
                       // console.log(dataString);
        
                        setTimeout(async () => { 
                     
      let sub  =     (await this.request('post', false, 'ionic_postPushToken', dataString)).subscribe(
          async (res) => {
            if( !res){ return;}
        //  console.log(res)

          /* add app has been used marker */
          
          await this.storage.setString(appUsedClub,'true').then(async (data: any) => {


                    });
          }); 


          this.subscriptions.add(sub)
          }, 1000)
          });
          
    }
    

    async switchLogout() {

      //this.isAuthenticated.next(false);
      //this.isLoggedIn.next(false);


      let info = await Device.getInfo();
      let platform = info.platform
      let device = await Device.getId();
      let uuid = device.identifier
      
      let dataString = {  'platform': platform,
                          'uuid': uuid
                        };
      

      /* logout on server */ 
    const sub =  (await this.request('post', false, this.logoutURL, dataString)).subscribe( 
        async (res) => {
         // console.log(res)
         // this.storage.clear();
      /* reset colours */ 

         document.documentElement.style.setProperty(`--ion-color-primary`, '#ff7800'); 
         document.documentElement.style.setProperty(`--ion-color-base-rgb`, 'rgb(255,120,0)'); 
         document.documentElement.style.setProperty(`--ion-color-primary-shade`, 'rgb(255,120,0)'); 
         document.documentElement.style.setProperty(`--ion-color-primary-tint`, 'rgb(255,120,0)'); 

         document.documentElement.style.setProperty(`--ion-color-primary-contrast`, '#FFFFFF'); 
         document.documentElement.style.setProperty(`--ion-color-contrast-rgb`, 'rgb(255,255,255)'); 

         document.documentElement.style.setProperty(`--ion-color-tertiary`, '#ff7800');
         document.documentElement.style.setProperty(`--ion-color-tertiary-rgb`, 'rgb(255,120,0)'); 
         document.documentElement.style.setProperty(`--ion-color-tertiary-shade`, 'rgb(255,120,0)'); 
         document.documentElement.style.setProperty(`--ion-color-tertiary-tint`, 'rgb(255,120,0)');

        /* remove push token */

        const deleteToken = async () => {
          await FirebaseMessaging.deleteToken();
        };
    
        });

      this.subscriptions.add(sub)


      
    }
    
async loadToken() {

      await this.storage.getObject('clubDetails').then(async (data: any) => {

        if (data && data['jwttoken']) {
          this.isAuthenticated.next(true);
        }
        else{

this.isAuthenticated.next(false);
        }
   })
  }
async addEventToCalendar(title,location,startDate){


  if( Capacitor.isNativePlatform()){


    let y = startDate[0]
let m = startDate[1]
let d = startDate[2]
let h = startDate[3]
let i = startDate[4]

  let startTime = new Date(y,m,d,h,i,0,0)

  h = parseInt(h) + 1;

  console.log(h)

  let endDate = new Date(y,m,d,h,i,0,0)

  console.log(startTime)
  console.log(endDate)

  console.log(title)
  console.log(location)
  console.log(startDate)

  let notes=null;

  this.calendar.createEventInteractively(title,location,notes,startTime,endDate).then(
    (msg) => { console.log(msg); },
    (err) => { console.log(err); }
  );

 }

 if( !Capacitor.isNativePlatform()){

  /* create ICS download */

  let dataString = {  
  'title': title,
  'location': location,
  'startDate': startDate
};
console.log(dataString);

(await this.request('post', false, 'ionic_postCreateICS', dataString)).subscribe(
  
async (res) => {

if( !res){ return;}



console.log(res)

let url = res.url

this.openPdf(url)


});

  

}


}
  updateClubName(value){
    this.clubName = value;
}
hasMultiClubs(value){
  this.multiClubs = value;
}



/*
async showAD(){

  let adMobdsId;
  if( this.appPlatform == "android"){
    adMobdsId = 'ca-app-pub-8100627961827814/1437240213'
  }else{
    adMobdsId = 'ca-app-pub-8100627961827814/4610198466'
  }
  const options: AdOptions = {
    adId: adMobdsId,
    isTesting: false,
    npa: true
  };
  await AdMob.prepareInterstitial(options);
  await AdMob.showInterstitial();
            
 }*/



getSuccess() {
  return  EMPTY
}

getFailed() {
  return EMPTY
}

getRetryFailed() {
  return EMPTY
}

getAuthFailed() {
  return EMPTY
}

getToken() {
  let jwttoken = this.userService.jwttoken;
  return jwttoken;
}


 
  public async checkWebPushPermissions(): Promise<void> {

    let permission =  await FirebaseMessaging.checkPermissions();

    console.log("PUSH PERMISSION" + permission.receive)
  
     if(permission.receive != 'granted'){

      const result = await FirebaseMessaging.requestPermissions();
           this.pushPermission.next(false)
     }
     else if(permission.receive == 'granted'){
           this.getPushToken()
           this.pushPermission.next(true)
     }
   }


   public async getPushToken(): Promise<void> {


     const options: GetTokenOptions = {
       vapidKey: environment.firebase.vapidKey,
     };
     
     if (Capacitor.getPlatform() === "web") {
      options.serviceWorkerRegistration =
        await navigator.serviceWorker.register("firebase-messaging-sw.js");
    }
     const { token } = await FirebaseMessaging.getToken(options);

     let pushToken = token;


     await this.storage.setString('pushtoken',pushToken).then(async (data: any) => {
    });


            this.registerToken(pushToken)
  
    
     
   }


   async shareDetails(detail){

    console.log(detail)

// Share url only
await Share.share({
  text: detail,
});

   }

ngOnDestroy() {

  // unsubscribe to ensure no memory leaks
this.subscriptions.unsubscribe();
}




    
}

